<template>
  <div>
    <ListingLayout
      v-model="selectedRows"
      :is-loading="isLoading"
      :meta="meta"
      :bread-crumbs="breadCrumbs"
      :is-filters-show="false"
      :headers="headers"
      :items="list"
      :multi-select-rows="showHeaders"
      no-filters
      @filterOption="onFiltersChange"
    >
      <template v-slot:flespi_plugin_id="{ item }">
        <div class="open-link">
          <!-- @click="redirectIOTScreen(item)" -->
          <router-link :to="redirectIOTScreen(item)">
            {{ item.flespi_plugin_id }}
          </router-link>
        </div>
      </template>

      <template v-slot:tenant_names="{ item }">
        <div>
          <typography
            :text="item?.tenant_names?.join(', ')"
            text-clip
          />
        </div>
      </template>
      <template v-slot:url="{ item }">
        <div>
          <typography
            :text="item?.url"
            :text-show="false"
            copy-content
          />
        </div>
      </template>

      <!-- add Filters -->
      <!-- <template #addFilters> </template> -->
      <!-- add Filters -->

      <!-- Action Modals -->
      <template #actionModals>
        <div
          v-if="showHeaders"
          class="d-flex"
          style="gap: 0.5rem"
        >
          <bulk-assign
            v-if="selectedRows.length > 0"
            @apply="onApply"
          />
          <v-btn
            v-if="$admin.can('flespi-plugins.sync')"
            color="primary"
            dark
            :loading="isLoading"
            @click="syncData"
          >
            {{ $t("sync") }}
          </v-btn>
        </div>
      </template>
      <!-- Action Modals -->
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    BulkAssign: () => import("./utils/BulkAssign.vue"),
    Typography: () => import("@/components/base/Typography.vue"),
  },
  mixins: [ListingLayoutMixins],
  props: {
    showHeaders: {
      type: Boolean,
      default() {
        return true;
      },
    },
    breadCrumbs: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  data() {
    return {
      filters: {},
      subFilters: {},
      //
      selectedRows: [],
      //
      headers: [
        {
          text: this.$t("flespi_plugin_id"),
          align: "left",
          sortable: true,
          value: "flespi_plugin_id",
          width: "25%",
        },
        {
          text: this.$t("name"),
          align: "left",
          sortable: false,
          value: "name",
          width: "25%",
        },
        {
          text: this.$t("status"),
          align: "left",
          sortable: false,
          value: "status",
          width: "25%",

          //   showAdmin: true,
        },
        {
          text: this.$t("assigned_tenant"),
          align: "left",
          sortable: false,
          value: "tenant_names",
          width: "25%",

          //   showAdmin: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "flespiPlugin/getIsLoading",
      meta: "flespiPlugin/getMeta",
      list: "flespiPlugin/getPluginsList",
    }),
  },
  watch: {
    options() {
      this.fetchData();
    },
  },
  beforeDestroy() {
    const params = {
      name: "plugins",
      filters: {
        page: this.options.page,
        itemsPerPage: this.options.itemsPerPage,
      },
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  methods: {
    async fetchData() {
      // if (!this.list.length > 0) {
      await this.$store.dispatch("flespiPlugin/list", this.options);
      // }
    },
    async syncData() {
      await this.$store.dispatch("flespiPlugin/pluginSync", this.options);
    },
    redirectIOTScreen(row) {
      return {
        path: "/iot-devices",
        query: { flespi_plugin_id: row.flespi_plugin_id },
      };
      // this.$router.push({
      //   path: "/iot-devices",
      //   query: { flespi_plugin_id: row.flespi_plugin_id },
      // });
    },
    //
    //
    async onApply(v) {
      const params = {
        action: v.value,
        tenant_ids: v.tenant_ids,
        flespi_plugin_ids: this.selectedRows.map((r) => r.flespi_plugin_id),
      };
      await this.$store
        .dispatch("flespiPlugin/bulkAssign", params)
        .then((res) => {
          this.$store.dispatch("alerts/success", res.message);
          this.fetchData();
        })
        .catch((err) => {
          this.$store.dispatch("alerts/error", err?.response?.data?.message);
        });
    },
  },
};
</script>
